.header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    color: #FFF;
    &.sticky {
        animation: headerStick .1s ease-out;
        position: fixed;
        top: 0;
        background: url('/img/header-bg.jpg') no-repeat center;
        background-size: cover;
        border-bottom: 4px solid $main-color;
        box-shadow: 0 0 10px rgba(0, 0, 0, .5);
        z-index: 100;
        header {
            padding-top: $pad/4;
            padding-bottom: $pad/4;
        }
        .logo {
            width: 280px;
        }
    }
    &:not(.sticky) {
        animation: headerUnstick .1s ease-out;
    }
	header {
        @extend %site-flex;
        padding-top: $pad/2;
        padding-bottom: $pad/2;
		align-items: center;
        justify-content: space-between;
	}
	.logo {
        display: block;
        width: 360px;
        text-align: center;
        will-change: auto;
        transition: .1s ease-out;
		img {
			display: block;
            width: 100%;
        }
    }
	.social {
        padding-left: 50px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
		text-align: right;
        font-family: $alt-font;
        a {
            @extend %transition;
            display: inline-block;
            padding: 10px;
            color: #ACACAC;
            font-size: 1.3em;
            text-decoration: none;
            white-space: nowrap;
            &:hover, &:focus {
                color: #FFF;
                text-shadow: 0 0 20px rgba(lighten(#FFF, 10%), .75);
            }
            &:last-child {
                margin-right: -10px;
            }
            svg {
                display: block;
                min-width: 28px;
                max-width: 28px;
            }
        }
        .btn {
            margin-left: 25px;
        }
	}
}
.admin-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 15px 30px;
    background: #000;
    color: #FFF;
    + .header:not(.sticky) {
        top: auto;
    }
    select {
        margin-left: 10px;
        background: none;
        border: 2px solid #FFF;
        border-radius: 4px;
        font-size: 1em;
        color: #FFF;
        option {
            color: #000;
        }
    }
    .toggle-page-content {
        @extend %link;
        color: #7F7F7F;
        &:hover, &:focus {
            color: #FFF;
        }
    }
    .full-width,
    .page-content {
        margin-top: 10px;
        width: 100%;
    }
    .page-content {
        @extend %transition;
        display: grid;
        grid-template-columns: 1fr 3fr 1fr;
        overflow: auto;
        max-height: 100vh;
        &.collapse {
            max-height: 0;
        }
        strong {
            margin: 10px 0;
            font-size: 1.1em;
            font-weight: 900;
        }
        p {
            margin-bottom: .75em;
            &:nth-of-type(3n-2), &:nth-of-type(3n-1) {
                margin-right: 20px;
            }
        }
    }
}

@media (max-width: 1200px) {
    .header {
        &.sticky .logo {
            width: 220px;
        }
        .logo {
            width: 250px;
        }
        .social {
            padding-left: 0;
            a {
                padding: 6px;
            }
            a, .btn {
                font-size: 1.1em;
            }
            .btn {
                margin-left: 10px;
            }
        }
    }
}
@media (max-width: 900px) {
	.header {
		header {
            flex-wrap: wrap;
        }
        &.sticky .logo {
            width: 180px;
        }
		.logo {
            min-width: 0;
		    width: 200px;
		}
        .social {
            justify-content: center;
            .btn {
                display: none;
            }
        }
    }
}
@media (max-width: 620px) {
    .header .social {
        margin-top: 10px;
        width: 100%;
        .phone {
            margin-right: auto;
        }
    }
}
@media (max-width: 420px) {
    .header {
        header {
            padding: 30px 5%;
        }
        .social {
            padding-left: 0;
            margin-left: auto;
            a {
                padding: 4px 6px;
            }
            .phone {
                padding: 5px 0;
                font-size: 1.2em;
            }
        }
    }
}

@keyframes headerStick {
    from { margin-top: -110px; }
    to { margin-top: 0; }
}

@keyframes headerUnstick {
    from { margin-top: 110px; }
    to { margin-top: 0; }
}
