.timetable {
    .swf {
        padding-top: 0;
        padding-bottom: 0;
    }
    --block-height: 80px;
    .times, .day {
        display: flex;
        flex-direction: column;
        > * {
            display: flex;
            align-items: center;
            height: var(--block-height);
            margin-bottom: 0;
            box-sizing: border-box;
        }
    }
    .times {
        flex: 2;
        min-width: 60px;
        > span:first-child {
            margin-top: var(--block-height);
        }
    }
    .day {
        flex: 3;
        min-width: 100px;
        border-left: 1px solid darken($secondary-color, 5%);
        > * {
            display: flex;
            padding: 10px;
            align-content: center;
            justify-content: center;
        }
        h3 {
            font-size: 1.4em;
        }
        > div {
            @extend %transition;
            flex-direction: column;
            background: darken($secondary-color, 2%);
            outline: none;
            cursor: default;
            span {
                margin-top: -12px;
                opacity: 0;
                transition: inherit;
            }
            &.spacer {
                background: none!important;
            }
            &:hover, &:focus {
                background: darken($secondary-color, 4%);
                span {
                    margin-top: 5px;
                    opacity: 0.5;
                }
            }
        }
    }
}

@media (max-width: 900px) {
    .timetable {
        .swf {
            flex-wrap: wrap;
            padding-top: 40px;
            padding-bottom: 40px;
        }
        .times {
            display: none;
        }
        .day {
            flex-basis: 50%;
            margin-bottom: 40px;
            border-left: 0;
            &:last-child {
                display: none;
            }
            h3 {
                font-size: 1.6em;
            }
            > div {
                min-width: 240px;
                margin: 0 auto 2px!important;
                &.spacer {
                    display: none;
                }
                span {
                    margin-top: 5px;
                    opacity: 0.5;
                }
            }
        }
    }
}
@media (max-width: 600px) {
    .timetable {
        .swf {
            flex-wrap: nowrap;
            flex-direction: column;
        }
    }
}
