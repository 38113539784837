// Australian School of Mountaineering Styles

%site-flex {
	display: flex;
	margin: 0 auto;
	max-width: 1440px;
	padding: $pad 5%;
}

%transition {
	transition: .15s ease-in-out;
}

%link {
	@extend %transition;
	padding-bottom: 0.1em;
	border-bottom: 1px solid;
	text-decoration: none;
}

%alt-heading {
	position: relative;
	display: inline-block;
	padding-top: 0.4em;
	margin-bottom: 0.8em;
	font-weight: 400;
	font-size: 1.8em;
	text-transform: uppercase;
	&::before {
		position: absolute;
		content: '';
		top: 0;
		left: 0;
		height: 4px;
		width: 90%;
		background: $main-color;
		transition: width .25s ease-out;
		will-change: contents;
	}
	&:hover::before {
		width: 100%;
	}
}
