.header header, .swf {
  display: flex;
  margin: 0 auto;
  max-width: 1440px;
  padding: 80px 5%;
}

.admin-header .toggle-page-content, .courses .course .about p a, .about.two-col a, .contact.swf a, .modal .forgot-password, .login-modal .forgot-password, .header .social a, .admin-header .page-content, .timetable .day > div, .form-control, .btn, .footer-copy a, .footer .swf .social a, .footer .swf .other-links a, .footer-nav a, .main-nav a, .about.two-col .img-wrap img, .swf.login .loader, .modal .close, .login-modal .close, .modal .loader, .login-modal .loader, .file-upload label {
  transition: .15s ease-in-out;
}

.admin-header .toggle-page-content, .courses .course .about p a, .about.two-col a, .contact.swf a, .modal .forgot-password, .login-modal .forgot-password {
  padding-bottom: 0.1em;
  border-bottom: 1px solid;
  text-decoration: none;
}

.hero h2, .courses .swf > .about h1, .courses .course .about h3, .about.two-col h3, .map h2 {
  position: relative;
  display: inline-block;
  padding-top: 0.4em;
  margin-bottom: 0.8em;
  font-weight: 400;
  font-size: 1.8em;
  text-transform: uppercase;
}

.hero h2::before, .courses .swf > .about h1::before, .courses .course .about h3::before, .about.two-col h3::before, .map h2::before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  height: 4px;
  width: 90%;
  background: #F2B51A;
  transition: width .25s ease-out;
  will-change: contents;
}

.hero h2:hover::before, .courses .swf > .about h1:hover::before, .courses .course .about h3:hover::before, .about.two-col h3:hover::before, .map h2:hover::before {
  width: 100%;
}

.header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  color: #FFF;
}

.header.sticky {
  -webkit-animation: headerStick .1s ease-out;
          animation: headerStick .1s ease-out;
  position: fixed;
  top: 0;
  background: url("/img/header-bg.jpg") no-repeat center;
  background-size: cover;
  border-bottom: 4px solid #F2B51A;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  z-index: 100;
}

.header.sticky header {
  padding-top: 20px;
  padding-bottom: 20px;
}

.header.sticky .logo {
  width: 280px;
}

.header:not(.sticky) {
  -webkit-animation: headerUnstick .1s ease-out;
          animation: headerUnstick .1s ease-out;
}

.header header {
  padding-top: 40px;
  padding-bottom: 40px;
  align-items: center;
  justify-content: space-between;
}

.header .logo {
  display: block;
  width: 360px;
  text-align: center;
  will-change: auto;
  transition: .1s ease-out;
}

.header .logo img {
  display: block;
  width: 100%;
}

.header .social {
  padding-left: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: right;
  font-family: "Montserrat";
}

.header .social a {
  display: inline-block;
  padding: 10px;
  color: #ACACAC;
  font-size: 1.3em;
  text-decoration: none;
  white-space: nowrap;
}

.header .social a:hover, .header .social a:focus {
  color: #FFF;
  text-shadow: 0 0 20px rgba(255, 255, 255, 0.75);
}

.header .social a:last-child {
  margin-right: -10px;
}

.header .social a svg {
  display: block;
  min-width: 28px;
  max-width: 28px;
}

.header .social .btn {
  margin-left: 25px;
}

.admin-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 15px 30px;
  background: #000;
  color: #FFF;
}

.admin-header + .header:not(.sticky) {
  top: auto;
}

.admin-header select {
  margin-left: 10px;
  background: none;
  border: 2px solid #FFF;
  border-radius: 4px;
  font-size: 1em;
  color: #FFF;
}

.admin-header select option {
  color: #000;
}

.admin-header .toggle-page-content {
  color: #7F7F7F;
}

.admin-header .toggle-page-content:hover, .admin-header .toggle-page-content:focus {
  color: #FFF;
}

.admin-header .full-width,
.admin-header .page-content {
  margin-top: 10px;
  width: 100%;
}

.admin-header .page-content {
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  overflow: auto;
  max-height: 100vh;
}

.admin-header .page-content.collapse {
  max-height: 0;
}

.admin-header .page-content strong {
  margin: 10px 0;
  font-size: 1.1em;
  font-weight: 900;
}

.admin-header .page-content p {
  margin-bottom: .75em;
}

.admin-header .page-content p:nth-of-type(3n-2), .admin-header .page-content p:nth-of-type(3n-1) {
  margin-right: 20px;
}

@media (max-width: 1200px) {
  .header.sticky .logo {
    width: 220px;
  }
  .header .logo {
    width: 250px;
  }
  .header .social {
    padding-left: 0;
  }
  .header .social a {
    padding: 6px;
  }
  .header .social a, .header .social .btn {
    font-size: 1.1em;
  }
  .header .social .btn {
    margin-left: 10px;
  }
}

@media (max-width: 900px) {
  .header header {
    flex-wrap: wrap;
  }
  .header.sticky .logo {
    width: 180px;
  }
  .header .logo {
    min-width: 0;
    width: 200px;
  }
  .header .social {
    justify-content: center;
  }
  .header .social .btn {
    display: none;
  }
}

@media (max-width: 620px) {
  .header .social {
    margin-top: 10px;
    width: 100%;
  }
  .header .social .phone {
    margin-right: auto;
  }
}

@media (max-width: 420px) {
  .header header {
    padding: 30px 5%;
  }
  .header .social {
    padding-left: 0;
    margin-left: auto;
  }
  .header .social a {
    padding: 4px 6px;
  }
  .header .social .phone {
    padding: 5px 0;
    font-size: 1.2em;
  }
}

@-webkit-keyframes headerStick {
  from {
    margin-top: -110px;
  }
  to {
    margin-top: 0;
  }
}

@keyframes headerStick {
  from {
    margin-top: -110px;
  }
  to {
    margin-top: 0;
  }
}

@-webkit-keyframes headerUnstick {
  from {
    margin-top: 110px;
  }
  to {
    margin-top: 0;
  }
}

@keyframes headerUnstick {
  from {
    margin-top: 110px;
  }
  to {
    margin-top: 0;
  }
}

.timetable {
  --block-height: 80px;
}

.timetable .swf {
  padding-top: 0;
  padding-bottom: 0;
}

.timetable .times, .timetable .day {
  display: flex;
  flex-direction: column;
}

.timetable .times > *, .timetable .day > * {
  display: flex;
  align-items: center;
  height: var(--block-height);
  margin-bottom: 0;
  box-sizing: border-box;
}

.timetable .times {
  flex: 2;
  min-width: 60px;
}

.timetable .times > span:first-child {
  margin-top: var(--block-height);
}

.timetable .day {
  flex: 3;
  min-width: 100px;
  border-left: 1px solid #050505;
}

.timetable .day > * {
  display: flex;
  padding: 10px;
  align-content: center;
  justify-content: center;
}

.timetable .day h3 {
  font-size: 1.4em;
}

.timetable .day > div {
  flex-direction: column;
  background: #0d0d0d;
  outline: none;
  cursor: default;
}

.timetable .day > div span {
  margin-top: -12px;
  opacity: 0;
  transition: inherit;
}

.timetable .day > div.spacer {
  background: none !important;
}

.timetable .day > div:hover, .timetable .day > div:focus {
  background: #080808;
}

.timetable .day > div:hover span, .timetable .day > div:focus span {
  margin-top: 5px;
  opacity: 0.5;
}

@media (max-width: 900px) {
  .timetable .swf {
    flex-wrap: wrap;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .timetable .times {
    display: none;
  }
  .timetable .day {
    flex-basis: 50%;
    margin-bottom: 40px;
    border-left: 0;
  }
  .timetable .day:last-child {
    display: none;
  }
  .timetable .day h3 {
    font-size: 1.6em;
  }
  .timetable .day > div {
    min-width: 240px;
    margin: 0 auto 2px !important;
  }
  .timetable .day > div.spacer {
    display: none;
  }
  .timetable .day > div span {
    margin-top: 5px;
    opacity: 0.5;
  }
}

@media (max-width: 600px) {
  .timetable .swf {
    flex-wrap: nowrap;
    flex-direction: column;
  }
}

/* --- HTML RESET --- */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* --- SHARED STYLES --- */
::-moz-selection {
  color: #FFF;
  background: #F2B51A;
  text-shadow: 1px 1px 10px #F2B51A;
}

::selection {
  color: #FFF;
  background: #F2B51A;
  text-shadow: 1px 1px 10px #F2B51A;
}

body {
  font-family: "Raleway", sans-serif;
  font-size: .75em;
}

h1, h2, h3, h4, h5, h6 {
  margin-bottom: 0.2em;
  font-family: "Montserrat";
  font-weight: 600;
  line-height: 1;
}

h1 {
  font-size: 4em;
}

h2 {
  font-size: 3.2em;
}

h3 {
  font-size: 1.8em;
}

p {
  font-size: 1.1em;
  line-height: 1.5;
}

.img-wrap img {
  display: block;
  width: 100%;
}

.swf.col {
  flex-direction: column;
}

.swf.centered {
  align-items: center;
}

.two-col {
  display: flex;
  align-items: center;
}

.two-col > * {
  width: 50%;
}

.two-col > *:first-child.info {
  padding-left: 5%;
}

.two-col > *:first-child .inner {
  float: right;
  padding-right: 10%;
}

.two-col > *:last-child.info {
  padding-right: 5%;
}

.two-col > *:last-child .inner {
  padding-left: 10%;
}

.two-col .info, .two-col .inner {
  box-sizing: border-box;
}

.two-col .inner {
  max-width: 720px;
}

.notify {
  position: fixed;
  top: 75px;
  right: 30px;
  padding: 26.66667px;
  background: #F2B51A;
  box-shadow: 5px 5px 30px #121212;
  color: #FFF;
}

.notify.hide {
  display: none;
}

.notify a {
  margin-left: 5px;
  font-family: "Montserrat";
  font-size: 1.2em;
  color: #FFF;
  text-decoration: none;
}

.dark-row {
  background: #121212;
  color: #FFF;
}

.page-heading .swf {
  padding-bottom: 40px;
  justify-content: center;
}

.page-heading .swf h1 {
  margin-bottom: 0;
}

/* --- FORM INPUTS --- */
.input-group {
  position: relative;
  margin: 1.5em 0;
}

.input-group:first-child {
  margin-top: 0;
}

.input-group:last-child {
  margin-bottom: 0;
}

.input-group .form-label, .input-group .form-control {
  font-size: 1.1em;
  line-height: 1.2;
  color: #333;
}

.input-group .form-label {
  position: absolute;
  top: 1em;
  left: 1.2em;
  display: block;
  opacity: 0.75;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  will-change: auto;
  transition: .1s ease-out;
}

.input-group .form-label small {
  font-size: 0.7em;
  opacity: 0.75;
}

.input-group .form-control.has-text ~ .form-label {
  top: -1.2em;
  left: 0;
  font-size: 0.9em;
}

.input-group.dark .form-control {
  color: #121212;
  background-color: #EFEFEF;
}

.input-group.dark .form-control:focus {
  border-color: #F2B51A;
}

.input-group.dark select.form-control {
  background-image: url("/img/chevron-dark.svg");
}

.input-group.light .form-control, .input-group.light .form-label {
  color: #EFEFEF;
}

.input-group.inline .form-control {
  padding: .5em 0;
}

.input-group.inline .form-label {
  top: .5em;
  left: 0;
  right: 0;
}

.form-control {
  display: block;
  width: 100%;
  padding: 1em 1.2em;
  background: #121212;
  border: 0;
  border-bottom: 4px solid transparent;
  border-radius: 0;
  color: #FFF;
  outline: none;
  box-sizing: border-box;
  font-family: "Raleway", sans-serif;
  -webkit-appearance: none;
}

.form-control:focus {
  border-color: #F2B51A;
}

select.form-control {
  background: url("/img/chevron.svg") no-repeat right 0.8em center;
  background-size: 20px;
}

select.form-control option {
  color: #000;
}

.btn {
  display: inline-block;
  min-width: 180px;
  padding: 1em 1.4em;
  background: #F2B51A;
  color: #3d2c03;
  font-family: "Montserrat";
  font-size: 1.2em;
  font-weight: 700;
  outline: 0;
  border: 0;
  border-radius: 0;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  box-sizing: border-box;
  cursor: pointer;
}

.btn:hover, .btn:focus {
  box-shadow: 1px 1px 15px #f2b51a;
  color: #FFF;
}

.btn:active {
  box-shadow: inset 1px 1px 10px #121212;
}

.btn:disabled, .btn.disabled {
  opacity: 0.8;
  pointer-events: none;
}

/* --- SITE LAYOUT --- */
.footer-copy {
  background: #000;
}

.footer-copy p:last-child {
  text-align: right;
}

.footer-copy span {
  display: inline-block;
}

.footer-copy span.hide-mobile {
  margin: 0 0.4em;
}

.footer-copy a {
  display: inline-block;
  color: #7F7F7F;
  text-decoration: none;
}

.footer-copy a:hover, .footer-copy a:focus {
  color: #FFF;
}

.footer .swf {
  flex-wrap: wrap;
  align-items: flex-start;
  padding-top: 40px;
  padding-bottom: 40px;
}

.footer .swf > * {
  flex: 1;
  min-width: 124px;
}

.footer .swf .img-wrapper {
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.footer .swf .img-wrapper img {
  max-width: 220px;
  width: 135px;
}

.footer .swf .img-wrapper a {
  opacity: 0.46;
  transition: opacity 0.15s ease-out;
}

.footer .swf .img-wrapper a:hover {
  opacity: 1;
}

.footer .swf .social {
  margin-top: 20px;
}

.footer .swf .social a {
  display: inline-block;
  color: #7F7F7F;
  text-decoration: none;
  padding: 4px;
  width: 28px;
  height: 28px;
}

.footer .swf .social a:hover, .footer .swf .social a:focus {
  color: #FFF;
}

.footer .swf .social a svg {
  display: block;
  width: 100%;
}

.footer .swf .other-links p {
  margin: 1em 0;
}

.footer .swf .other-links a {
  display: inline-block;
  color: #7F7F7F;
  text-decoration: none;
}

.footer .swf .other-links a:hover, .footer .swf .other-links a:focus {
  color: #FFF;
}

.footer-nav .img-wrapper {
  width: 100%;
}

.footer-nav a {
  display: inline-block;
  padding: 0.4em 0.8em;
  color: #FFF;
  font-family: "Montserrat";
  font-size: 1.3em;
  text-decoration: none;
  text-transform: uppercase;
  border: 2px solid transparent;
  outline: none;
}

.footer-nav a:hover, .footer-nav a:focus {
  text-shadow: 0 0 20px rgba(255, 255, 255, 0.75);
}

.footer-nav a:active {
  color: #F2B51A;
}

.footer-nav a.active {
  border-color: #FFF;
}

/* --- NAV --- */
.main-nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: auto;
}

.main-nav a {
  display: inline-block;
  color: #F2B51A;
  padding: 0.4em 0.8em;
  margin-left: 10px;
  margin-right: -0.8em;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  font-family: "Montserrat";
  font-size: 1.3em;
  border: 2px solid transparent;
  outline: none;
}

.main-nav a:first-child {
  margin-left: 0;
}

.main-nav a:hover, .main-nav a:focus {
  text-shadow: 0 0 20px rgba(245, 197, 74, 0.75);
}

.main-nav a:active {
  color: #FFF;
}

.main-nav a.active {
  border-color: #F2B51A;
  text-shadow: 0 0 10px rgba(245, 197, 74, 0.75);
}

/* --- HERO --- */
.hero {
  display: flex;
  align-content: center;
  min-height: 80vh;
  padding-top: 140px;
  color: #FFF;
  background: url(/img/asm-hero-background.jpg) no-repeat top center;
  background-size: cover;
}

@media (min-width: 1920px) {
  .hero {
    background-size: cover;
  }
}

.hero .info {
  position: relative;
  width: 50%;
}

.hero .info p {
  margin-right: 25%;
}

.hero .info .file-upload {
  bottom: -100px;
}

.hero .img-wrap {
  flex: 1;
  margin: -80px 0;
}

.hero .video {
  flex: 1;
}

.hero .video .responsive-frame {
  position: relative;
  height: 0;
  padding-bottom: 56.25%;
}

.hero .video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.hero h3 {
  margin-top: 30px;
}

.hero p {
  margin-bottom: 30px;
}

/* --- CTA ROW --- */
.cta.dark-row {
  color: #F2B51A;
}

.cta.dark-row .swf h2 span {
  color: #FFF;
}

.cta .swf {
  justify-content: space-evenly;
}

.cta .swf h2 {
  margin: 20px 0;
}

.cta .swf h2:last-child {
  margin: 0;
  text-align: center;
}

.cta .swf h2 span {
  display: block;
  color: #F2B51A;
}

/* --- OUR COURSES --- */
.courses .swf {
  flex-direction: column;
  align-items: flex-start;
}

.courses .swf > .about {
  margin-right: 50%;
  margin-bottom: 80px;
}

.courses .swf > .about p {
  margin-right: 25%;
}

.courses .course {
  display: flex;
  align-items: center;
  margin-bottom: 80px;
}

.courses .course:last-child {
  margin-bottom: 0;
}

.courses .course > a {
  position: relative;
  display: block;
  flex: 1;
  max-width: 720px;
}

.courses .course > a img {
  display: block;
  width: 100%;
  max-width: 720px;
}

.courses .course .about {
  flex: 1;
  padding: 0 5%;
}

.courses .course .about b {
  font-weight: 700;
}

.courses .course .about p {
  margin: 1em 0;
}

.courses .course .about p a {
  color: #F2B51A;
}

.courses .course .about p a:hover {
  color: #121212;
}

.courses .course .about p a:active {
  color: #cd970c;
}

.courses .course .about .url {
  display: flex;
  align-items: center;
}

.courses .course .about .url svg {
  width: 24px;
  height: 24px;
  min-width: 24px;
  margin-right: 4px;
}

.courses .course .about .url p {
  flex: 1;
  white-space: nowrap;
}

/* --- TESTIMONIALS --- */
.testimonials h2 {
  margin-bottom: 1em;
  width: 100%;
}

.testimonials p {
  margin-bottom: 0.5em;
}

.testimonials p:last-child {
  margin-bottom: 0;
}

.testimonials strong {
  font-weight: 700;
}

.testimonials .swf {
  flex-wrap: wrap;
  justify-content: space-between;
}

.testimonials .testimonial {
  flex: 0 1 42%;
  min-width: 350px;
  margin-bottom: 40px;
  box-sizing: border-box;
}

.testimonials .img-wrapper {
  margin-right: 20px;
}

.testimonials .img-wrapper img {
  display: block;
  width: 100px;
}

/* --- ABOUT US --- */
.about.two-col {
  min-height: 500px;
  max-height: 680px;
  background: #121212;
  color: #FFF;
  overflow: hidden;
}

.about.two-col:hover .img-wrap img {
  opacity: 0.8;
}

.about.two-col p {
  margin-bottom: 1em;
}

.about.two-col p:last-child {
  margin-bottom: 0;
}

.about.two-col a {
  display: inline-block;
  color: #FFF;
}

.about.two-col .img-wrap {
  position: relative;
}

.about.two-col .img-wrap img {
  opacity: 0.6;
}

/* --- MAP --- */
#map {
  height: 960px;
  max-height: 90vh;
}

.map.two-col .results {
  display: none;
}

.map.two-col .results.show {
  display: block;
}

/* --- CONTACT --- */
.contact.swf {
  width: 100%;
  flex-wrap: wrap;
}

.contact.swf .row {
  padding-top: 40px;
}

.contact.swf a {
  color: #F2B51A;
}

.contact.swf a:hover, .contact.swf a:focus {
  color: #cd970c;
}

.contact.swf .input-group .has-text ~ .form-label {
  padding: 0 4px;
  color: #FFF;
  background: #121212;
  opacity: 0.9;
}

/* --- LOGIN --- */
.swf.login {
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

.swf.login .info {
  margin-right: 40px;
  width: auto;
}

.swf.login .loader {
  float: left;
  opacity: 0;
}

.swf.login .loader.show {
  opacity: 1;
}

.swf.login .loader.show ~ .forgot-password {
  display: none;
}

.swf.login .form {
  flex: 360px;
  max-width: 360px;
}

.swf.login .form p {
  margin: 0;
  color: #C4C4C4;
}

.swf.login .form p strong {
  color: #FFF;
}

.swf.login .form .btn {
  float: right;
  padding: 0.5em 0.7em;
  min-width: 100px;
}

.swf.login .form-control.has-text ~ .form-label {
  background: #FFF;
  color: #000;
}

/* --- GALLERY --- */
.gallery-row h2 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.gallery {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

/* --- MODAL --- */
.overlay, .signup-modal-wrapper, .login-modal-wrapper {
  position: fixed;
  display: none;
  z-index: 1000;
}

.overlay {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(18, 18, 18, 0.75);
  -webkit-animation: fade-in .15s forwards ease-out;
          animation: fade-in .15s forwards ease-out;
}

.overlay.show {
  display: block;
}

.signup-modal-wrapper, .login-modal-wrapper {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  align-items: center;
  justify-items: center;
  pointer-events: none;
}

.signup-modal-wrapper.show, .login-modal-wrapper.show {
  display: flex;
}

.modal, .login-modal {
  max-width: 480px;
  display: none;
  margin: auto;
  background: #121212;
  border: 10px solid #000;
  color: #FFF;
  -webkit-animation: slam .25s forwards ease-out;
          animation: slam .25s forwards ease-out;
  pointer-events: all;
}

.modal .inner, .login-modal .inner {
  padding: 40px 20px;
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
}

.modal .close, .login-modal .close {
  display: inline-block;
  position: absolute;
  top: -20px;
  right: -20px;
  width: 40px;
  height: 40px;
  font-size: 1.4em;
  font-weight: 700;
  line-height: 38px;
  text-align: center;
  text-decoration: none;
  color: #F2B51A;
  background: #000;
  border-radius: 40px;
}

.modal .close:hover, .login-modal .close:hover {
  color: #FFF;
  transform: scale(1.25);
}

.modal .close:active, .login-modal .close:active {
  transform: scale(1.18);
}

.modal h1, .login-modal h1 {
  margin-bottom: 0.4em;
}

.modal h2, .login-modal h2 {
  margin-bottom: 0.8em;
}

.modal p, .login-modal p {
  margin-bottom: 40px;
}

.modal form::after, .login-modal form::after {
  display: table;
  content: '';
  clear: both;
}

.modal .form-control, .login-modal .form-control {
  color: #FFF;
}

.modal .forgot-password, .login-modal .forgot-password {
  position: absolute;
  bottom: 51px;
  left: 20px;
  color: #D3D3D3;
}

.modal .forgot-password:hover, .login-modal .forgot-password:hover {
  color: #FFF;
}

.modal .loader, .login-modal .loader {
  float: left;
  opacity: 0;
}

.modal .loader.show, .login-modal .loader.show {
  opacity: 1;
}

.modal .loader.show ~ .forgot-password, .login-modal .loader.show ~ .forgot-password {
  display: none;
}

.modal .btn, .login-modal .btn {
  float: right;
  padding: 0.5em 0.7em;
  min-width: 100px;
}

.results {
  display: flex;
  clear: both;
  width: 100%;
  padding: 1em 0;
  min-height: 30px;
  align-items: center;
}

.results:empty {
  display: none;
}

.results p {
  margin-bottom: 0;
}

.results svg {
  float: left;
  width: 32px;
  margin-right: 8px;
}

/* --- LOADER --- */
.loader {
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px;
}

.loader > div {
  display: inline-block;
  background-color: #FFF;
  height: 100%;
  width: 6px;
  margin-right: 1px;
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.loader .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.loader .rect3 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

.loader .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.loader .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

/* --- FILE UPLOADER --- */
.file-upload {
  position: absolute;
  bottom: 0;
  left: 0;
}

.file-upload input[type=file] {
  display: none;
}

.file-upload label {
  padding: 2px 4px;
  background: #121212;
  color: #F2B51A;
  cursor: pointer;
}

.file-upload label:hover {
  color: #FFF;
}

.file-upload label:active {
  color: #F2B51A;
}

.file-upload label svg {
  display: inline-block;
  vertical-align: middle;
}

.file-upload .img-dims {
  padding: 2px 4px;
  color: #FFF;
  background: #121212;
  font-size: .8em;
}

.file-upload .img-dims strong {
  font-weight: 700;
}

/* --- ANIMATIONS --- */
@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes slam {
  0% {
    transform: scale(10, 10);
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}

@keyframes slam {
  0% {
    transform: scale(10, 10);
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}

@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% {
    -webkit-transform: scaleY(0.4);
  }
  20% {
    -webkit-transform: scaleY(1);
  }
}

@keyframes sk-stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}

@-webkit-keyframes onAutoFillStart {
  from {
    /**/
  }
  to {
    /**/
  }
}

@keyframes onAutoFillStart {
  from {
    /**/
  }
  to {
    /**/
  }
}

@-webkit-keyframes onAutoFillCancel {
  from {
    /**/
  }
  to {
    /**/
  }
}

@keyframes onAutoFillCancel {
  from {
    /**/
  }
  to {
    /**/
  }
}

input:-webkit-autofill {
  -webkit-animation-name: onAutoFillStart;
          animation-name: onAutoFillStart;
  -webkit-transition: background-color 50000s ease-in-out 0s, color 50000s ease-in-out 0s;
  transition: background-color 50000s ease-in-out 0s, color 50000s ease-in-out 0s;
}

input:not(:-webkit-autofill) {
  -webkit-animation-name: onAutoFillCancel;
          animation-name: onAutoFillCancel;
}

/* --- TYPOGRAPHY --- */
@media (min-width: 30em) {
  body {
    font-size: calc(.75rem + ((1vw - 0.3em) * 0.2778));
  }
}

@media (min-width: 120em) {
  body {
    font-size: 1em;
  }
}

@media (min-width: 1661px) {
  .courses .course:nth-child(2n) .about {
    left: 5%;
  }
  .courses .course:nth-child(2n-1) .about {
    right: 5%;
  }
  .courses .course > a {
    flex: auto;
    max-width: 800px;
  }
  .courses .course > a img {
    position: relative;
    display: block;
    width: 100%;
    max-width: none;
  }
}

/* --- IPAD LAYOUT ---- */
@media (max-width: 1200px) {
  .footer-copy span {
    display: block;
  }
  .footer-copy span.hide-mobile {
    display: none;
  }
  .footer .fb-like {
    display: none;
  }
  .gallery {
    grid-template-columns: repeat(2, 1fr);
  }
  .main-nav a {
    padding-left: 13.33333px;
    padding-right: 13.33333px;
    font-size: 1.1em;
  }
  .courses .swf > .about {
    margin-right: 40%;
  }
  .courses .swf > .about p {
    margin-right: 0;
  }
}

@media (max-width: 1200px) and (min-width: 961px) {
  .about.two-col h2 {
    font-size: 3em;
  }
}

@media (max-width: 960px) {
  .swf {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .two-col {
    flex-direction: column;
  }
  .two-col.about {
    max-height: none;
  }
  .two-col.map {
    flex-direction: column-reverse;
  }
  .two-col > * {
    width: 100%;
  }
  .two-col .info {
    padding: 40px 5%;
  }
  .two-col .inner {
    padding: 0 !important;
  }
  .two-col .img-wrap img {
    margin: 0 auto;
  }
  .cta .swf {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media (min-width: 901px) {
  .courses .course:nth-child(2n) {
    flex-direction: row-reverse;
  }
  .courses .course:nth-child(2n) .about {
    padding-left: 0;
    text-align: right;
  }
  .courses .course:nth-child(2n) .about h3::before {
    left: auto;
    right: 0;
  }
  .courses .course:nth-child(2n) > a img {
    left: 10%;
  }
  .courses .course:nth-child(2n) .url {
    flex-direction: row-reverse;
  }
  .courses .course:nth-child(2n) .url svg {
    margin-right: 0;
    margin-left: 4px;
  }
  .courses .course:nth-child(2n-1) .about {
    padding-right: 0;
  }
  .courses .course:nth-child(2n-1) > a img {
    right: 10%;
  }
  .courses .course:nth-child(2n-1) .file-upload {
    left: auto;
    right: 0;
    text-align: right;
  }
  .courses .course .about {
    position: relative;
  }
  .courses .course > a {
    max-width: 800px;
    width: 720px;
  }
  .courses .course > a img {
    display: block;
    width: 100%;
    max-width: 800px;
  }
}

@media (max-width: 900px) {
  .hero .info {
    width: 90%;
  }
  .input-group.inline .form-control {
    min-width: 100%;
    margin-top: 5px;
  }
  .courses .swf {
    padding-left: 0;
    padding-right: 0;
  }
  .courses .swf > .about {
    margin-left: 5%;
  }
  .courses .course {
    margin-bottom: 60px;
  }
  .courses .course .about {
    padding: 0 5%;
  }
  .courses .course h2 {
    font-size: 2.2em;
  }
  .testimonials .testimonial {
    flex-grow: 9999;
  }
}

/* --- MOBILE LAYOUT ---- */
@media (max-width: 720px) {
  .footer-copy .swf {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .footer-copy .swf p:not(:last-child) {
    margin-bottom: 1em;
  }
}

@media (max-width: 680px) {
  .main-nav {
    margin-left: 0;
  }
  .footer-nav {
    justify-content: center;
  }
  .courses .course {
    flex-direction: column;
    margin-bottom: 80px;
  }
  .courses .course .about {
    padding-top: 20px;
  }
}

@media (max-width: 600px) {
  .swf {
    padding-top: 10%;
    padding-bottom: 10%;
  }
  .courses .swf > .about {
    margin-right: 5%;
    margin-bottom: 40px;
  }
  .hero .swf {
    padding-bottom: 5%;
  }
  .hero .info, .hero .video, .hero .img-wrap {
    width: 100%;
    margin: 0;
  }
  .hero .info p {
    margin-right: 0;
    margin-bottom: 3.5%;
  }
  .cta .swf h2 {
    margin-top: 0;
  }
  .footer .swf {
    flex-direction: column;
  }
  .footer .swf .social {
    margin-top: 0;
  }
  .footer-nav {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 20px;
  }
  .footer-nav a {
    margin-left: 0;
  }
  .footer-nav .img-wrapper {
    width: 100%;
  }
}

@media (max-width: 425px) {
  .main-nav a {
    padding-left: 8px;
    padding-right: 8px;
  }
  .footer .swf .other-links img {
    width: 100%;
  }
  h1, h2, h3, h4, h5, h6 {
    margin-bottom: 0.4em;
  }
  h1 {
    font-size: 2.8em;
  }
  h2, h3 {
    font-size: 2.3em;
  }
  .gallery {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 400px) {
  .main-nav {
    flex-direction: column;
    align-items: flex-end;
  }
  .main-nav a {
    margin-left: 0;
  }
}
/*# sourceMappingURL=main.css.map */