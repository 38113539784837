@import 'vars';
@import 'partials';
@import 'header';
@import 'timetable';

/* --- HTML RESET --- */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

/* --- SHARED STYLES --- */
::-moz-selection {
    color: #FFF;
	background: $main-color;
	text-shadow: 1px 1px 10px $main-color;
}
::selection {
    color: #FFF;
	background: $main-color;
	text-shadow: 1px 1px 10px $main-color;
}
body {
	font-family: $body-font;
	font-size: .75em;
}
h1, h2, h3, h4, h5, h6 {
	margin-bottom: 0.2em;
    font-family: $alt-font;
    font-weight: 600;
	line-height: 1;
}
h1 {
	font-size: 4em;
}
h2 {
	font-size: 3.2em;
}
h3 {
	font-size: 1.8em;
}
p {
	font-size: 1.1em;
	line-height: 1.5;
}
.img-wrap img {
    display: block;
    width: 100%;
}
.swf {
    @extend %site-flex;
    &.col {
        flex-direction: column;
    }
	&.centered {
		align-items: center;
	}
}
.two-col {
	display: flex;
	align-items: center;
	> * {
		width: 50%;
		&:first-child {
			&.info {
				padding-left: 5%;
			}
			.inner {
				float: right;
				padding-right: 10%;
			}
		}
		&:last-child {
			&.info {
				padding-right: 5%;
			}
			.inner {
				padding-left: 10%;
			}
		}
	}
	.info, .inner {
		box-sizing: border-box;
	}
	.inner {
		max-width: 720px;
	}
}
.notify {
    position: fixed;
    top: 75px;
    right: 30px;
    padding: $pad/3;
    background: $main-color;
    box-shadow: 5px 5px 30px $secondary-color;
    color: #FFF;
    &.hide {
        display: none;
    }
    a {
        margin-left: 5px;
        font-family: $alt-font;
        font-size: 1.2em;
        color: #FFF;
        text-decoration: none;
    }
}
.dark-row {
    background: $secondary-color;
	color: #FFF;
}
.page-heading .swf {
    padding-bottom: 40px;
    justify-content: center;
    h1 {
        margin-bottom: 0;
    }
}

/* --- FORM INPUTS --- */
.input-group {
    position: relative;
    margin: 1.5em 0;
    &:first-child {
        margin-top: 0;
    }
    &:last-child {
        margin-bottom: 0;
    }
    .form-label, .form-control {
        font-size: 1.1em;
        line-height: 1.2;
        color: #333;
    }
    .form-label {
        position: absolute;
        top: 1em;
        left: 1.2em;
        display: block;
        opacity: 0.75;
        user-select: none;
        will-change: auto;
        transition: .1s ease-out;
        small {
            font-size: 0.7em;
            opacity: 0.75;
        }
    }
    .form-control.has-text ~ .form-label {
        top: -1.2em;
        left: 0;
        font-size: 0.9em;
    }
    &.dark {
        .form-control {
            color: $secondary-color;
            background-color: #EFEFEF;
            &:focus {
                border-color: $main-color;
            }
        }
        select.form-control {
            background-image: url('/img/chevron-dark.svg');
        }
    }
    &.light {
        .form-control, .form-label {
            color:  #EFEFEF;
        }
    }
    &.inline {
        .form-control {
            padding: .5em 0;
        }
        .form-label {
            top: .5em;
            left: 0;
            right: 0;
        }
    }
}
.form-control {
    @extend %transition;
    display: block;
    width: 100%;
    padding: 1em 1.2em;
    background: #121212;
    border: 0;
    border-bottom: 4px solid transparent;
    border-radius: 0;
    color: #FFF;
    outline: none;
    box-sizing: border-box;
    font-family: $body-font;
    -webkit-appearance: none;
    &:focus {
        border-color: $main-color;
    }
}
select.form-control {
    background: url('/img/chevron.svg') no-repeat right .8em center;
    background-size: 20px;
    option {
        color: #000;
    }
}
.btn {
	@extend %transition;
	display: inline-block;
	min-width: 180px;
	padding: 1em 1.4em;
	background: $main-color;
	color: darken($main-color, 40%);
	font-family: $alt-font;
    font-size: 1.2em;
    font-weight: 700;
	outline: 0;
	border: 0;
    border-radius: 0;
    text-transform: uppercase;
	text-decoration: none;
	text-align: center;
	box-sizing: border-box;
	cursor: pointer;
	&:hover, &:focus {
		box-shadow: 1px 1px 15px rgba($main-color, 25%);
		color: #FFF;
	}
	&:active {
		box-shadow: inset 1px 1px 10px $secondary-color;
    }
    &:disabled, &.disabled {
        opacity: 0.8;
        pointer-events: none;
    }
}

/* --- SITE LAYOUT --- */
.footer-copy {
    background: #000;
    p:last-child {
        text-align: right;
    }
    span {
        display: inline-block;
        &.hide-mobile {
            margin: 0 0.4em;
        }
    }
    a {
        @extend %transition;
        display: inline-block;
        color: #7F7F7F;
        text-decoration: none;
        &:hover, &:focus {
            color: #FFF;
        }
    }
}
.footer .swf {
    flex-wrap: wrap;
    align-items: flex-start;
	padding-top: $pad/2;
    padding-bottom: $pad/2;
    > * {
        flex: 1;
        min-width: 124px;
    }
    .img-wrapper {
        margin-bottom: 20px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        img {
            max-width: 220px;
            width: 135px;
        }
        a {
            opacity: 0.46;
            transition: opacity 0.15s ease-out;
            &:hover {
                opacity: 1;
            }
        }
    }
    .social {
        margin-top: 20px;
        a {
            @extend %transition;
            display: inline-block;
            color: #7F7F7F;
            text-decoration: none;
            padding: 4px;
            width: 28px;
            height: 28px;
            &:hover, &:focus {
                color: #FFF;
            }
            svg {
                display: block;
                width: 100%;
            }
        }
    }
    .other-links {
        p {
            margin: 1em 0;
        }
        a {
            @extend %transition;
            display: inline-block;
            color: #7F7F7F;
            text-decoration: none;
            &:hover, &:focus {
                color: #FFF;
            }
        }
    }
}
.footer-nav {
    .img-wrapper {
        width: 100%;
    }
	a {
		@extend %transition;
        display: inline-block;
		padding: 0.4em 0.8em;
		color: #FFF;
		font-family: $alt-font;
		font-size: 1.3em;
        text-decoration: none;
        text-transform: uppercase;
        border: 2px solid transparent;
        outline: none;
		&:hover, &:focus {
			text-shadow: 0 0 20px rgba(lighten(#FFF, 10%), .75);
		}
		&:active {
			color: $main-color;
        }
        &.active {
            border-color: #FFF;
        }
	}
}

/* --- NAV --- */
.main-nav {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: auto;
	a {
		@extend %transition;
		display: inline-block;
		color: $main-color;
        padding: 0.4em 0.8em;
        margin-left: 10px;
        margin-right: -0.8em;
        text-transform: uppercase;
		text-decoration: none;
        text-align: center;
        font-family: $alt-font;
        font-size: 1.3em;
        border: 2px solid transparent;
        outline: none;
        &:first-child {
            margin-left: 0;
        }
		&:hover, &:focus {
			text-shadow: 0 0 20px rgba(lighten($main-color, 10%), .75);
		}
		&:active {
			color: #FFF;
        }
        &.active {
            border-color: $main-color;
            text-shadow: 0 0 10px rgba(lighten($main-color, 10%), .75);
        }
	}
}

/* --- HERO --- */
.hero {
    display: flex;
    align-content: center;
    min-height: 80vh;
    padding-top: 140px;
    color: #FFF;
    background: url(/img/asm-hero-background.jpg) no-repeat top center;
    background-size: cover;
    @media (min-width: 1920px) {
        background-size: cover;
    }
	.info {
        position: relative;
        width: 50%;
        p {
            margin-right: 25%;
        }
        .file-upload {
            bottom: -100px;
        }
    }
    .img-wrap {
        flex: 1;
        margin: -$pad 0;
    }
	.video {
		flex: 1;
		.responsive-frame {
			position: relative;
			height: 0;
			padding-bottom: 56.25%;
		}
		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
    }
    h2 {
        @extend %alt-heading;
    }
    h3 {
        margin-top: 30px;
    }
	p {
		margin-bottom: 30px;
	}
}

/* --- CTA ROW --- */
.cta.dark-row {
    color: $main-color;
    .swf h2 span {
        color: #FFF;
    }
}
.cta .swf {
    justify-content: space-evenly;
    h2 {
        margin: 20px 0;
        &:last-child {
            margin: 0;
            text-align: center;
        }
        span {
            display: block;
            color: $main-color;
        }
    }
}

/* --- OUR COURSES --- */
.courses {
    .swf {
        flex-direction: column;
        align-items: flex-start;
        > .about {
            margin-right: 50%;
            margin-bottom: $pad;
            h1 {
                @extend %alt-heading;
            }
            p {
                margin-right: 25%;
            }
        }
    }
    .course {
        display: flex;
        align-items: center;
        margin-bottom: $pad;
        &:last-child {
            margin-bottom: 0;
        }
        > a {
            position: relative;
            display: block;
            flex: 1;
            max-width: 720px;
            img {
                display: block;
                width: 100%;
                max-width: 720px;
            }
        }
        .about {
            flex: 1;
            padding: 0 5%;
            h3 {
                @extend %alt-heading;
            }
            b {
                font-weight: 700;
            }
            p {
                margin: 1em 0;
                a {
                    @extend %link;
                    color: $main-color;
                    &:hover {
                        color: $secondary-color;
                    }
                    &:active {
                        color: darken($main-color, 10%);
                    }
                }
            }
            .url {
                display: flex;
                align-items: center;
                svg {
                    width: 24px;
                    height: 24px;
                    min-width: 24px;
                    margin-right: 4px;
                }
                p {
                    flex: 1;
                    white-space: nowrap;
                }
            }
        }
    }
}

/* --- TESTIMONIALS --- */
.testimonials {
    h2 {
        margin-bottom: 1em;
        width: 100%;
    }
    p {
        margin-bottom: 0.5em;
        &:last-child {
            margin-bottom: 0;
        }
    }
    strong {
        font-weight: 700;
    }
    .swf {
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .testimonial {
        flex: 0 1 42%;
        min-width: 350px;
        margin-bottom: 40px;
        box-sizing: border-box;
    }
    .img-wrapper {
        margin-right: 20px;
        img {
            display: block;
            width: 100px;
        }
    }
}

/* --- ABOUT US --- */
.about.two-col {
    min-height: 500px;
    max-height: 680px;
	background: $secondary-color;
	color: #FFF;
    overflow: hidden;
    &:hover .img-wrap img {
        opacity: 0.8;
    }
    h3 {
        @extend %alt-heading;
    }
    p {
        margin-bottom: 1em;
        &:last-child {
            margin-bottom: 0;
        }
    }
    a {
        @extend %link;
        display: inline-block;
        color: #FFF;
    }
    .img-wrap {
        position: relative;
        img {
            @extend %transition;
            opacity: 0.6;
        }
    }
}

/* --- MAP --- */
#map {
	height: 960px;
	max-height: 90vh;
}
.map.two-col {
    .results {
        display: none;
        &.show {
            display: block;
        }
    }
}
.map h2 {
    @extend %alt-heading;
}

/* --- CONTACT --- */
.contact.swf {
    width: 100%;
    flex-wrap: wrap;
    .row {
        padding-top: $pad/2;
    }
    a {
        @extend %link;
        color: $main-color;
        &:hover, &:focus {
            color: darken($main-color, 10%);
        }
    }
    .input-group .has-text ~ .form-label {
        padding: 0 4px;
        color: #FFF;
        background: $secondary-color;
        opacity: 0.9;
    }
}

/* --- LOGIN --- */
.swf.login {
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    .info {
        margin-right: 40px;
        width: auto;
    }
    .loader {
        @extend %transition;
        float: left;
        opacity: 0;
        &.show {
            opacity: 1;
            ~ .forgot-password {
                display: none;
            }
        }
    }
    .form {
        flex: 360px;
        max-width: 360px;
        p {
            margin: 0;
            color: #C4C4C4;
            strong {
                color: #FFF;
            }
        }
        .btn {
            float: right;
            padding: 0.5em 0.7em;
            min-width: 100px;
        }
    }
    .form-control.has-text ~ .form-label {
        background: #FFF;
        color: #000;
    }
}

/* --- GALLERY --- */
.gallery-row h2 {
    padding-top: 30px;
    padding-bottom: 30px;
}
.gallery {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}

/* --- MODAL --- */
.overlay, .signup-modal-wrapper, .login-modal-wrapper {
    position: fixed;
    display: none;
    z-index: 1000;
}
.overlay {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba($secondary-color, .75);
    animation: fade-in .15s forwards ease-out;
    &.show {
        display: block;
    }
}
.signup-modal-wrapper, .login-modal-wrapper {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    align-items: center;
    justify-items: center;
    pointer-events: none;
    &.show {
        display: flex;
    }
}
.modal, .login-modal {
    max-width: 480px;
    display: none;
    margin: auto;
    background: $secondary-color;
    border: $pad/8 solid #000;
    color: #FFF;
    animation: slam .25s forwards ease-out;
    pointer-events: all;
    .inner {
        padding: $pad/2 $pad/4;
        height: 100%;
        overflow: auto;
        box-sizing: border-box;
    }
    .close {
        @extend %transition;
        display: inline-block;
        position: absolute;
        top: -20px;
        right: -20px;
        width: 40px;
        height: 40px;
        font-size: 1.4em;
        font-weight: 700;
        line-height: 38px;
        text-align: center;
        text-decoration: none;
        color: $main-color;
        background: #000;
        border-radius: 40px;
        &:hover {
            color: #FFF;
            transform: scale(1.25);
        }
        &:active {
            transform: scale(1.18);
        }
    }
    h1 {
        margin-bottom: 0.4em;
    }
    h2 {
        margin-bottom: 0.8em;
    }
    p {
        margin-bottom: $pad/2;
    }
    form::after {
        display: table;
        content: '';
        clear: both;
    }
    .form-control {
        color: #FFF;
    }
    .forgot-password {
        @extend %link;
        position: absolute;
        bottom: 51px;
        left: 20px;
        color: #D3D3D3;
        &:hover {
            color: #FFF;
        }
    }
    .loader {
        @extend %transition;
        float: left;
        opacity: 0;
        &.show {
            opacity: 1;
            ~ .forgot-password {
                display: none;
            }
        }
    }
    .btn {
        float: right;
        padding: 0.5em 0.7em;
        min-width: 100px;
    }
}
.results {
    display: flex;
    clear: both;
    width: 100%;
    padding: 1em 0;
    min-height: 30px;
    align-items: center;
    &:empty {
        display: none;
    }
    p {
        margin-bottom: 0;
    }
    svg {
        float: left;
        width: 32px;
        margin-right: 8px;
    }
}

/* --- LOADER --- */
.loader {
    width: 50px;
    height: 40px;
    text-align: center;
    font-size: 10px;
    > div {
        display: inline-block;
        background-color: #FFF;
        height: 100%;
        width: 6px;
        margin-right: 1px;
        -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
        animation: sk-stretchdelay 1.2s infinite ease-in-out;
    }
    .rect2 {
        -webkit-animation-delay: -1.1s;
        animation-delay: -1.1s;
    }
    .rect3 {
        -webkit-animation-delay: -1.0s;
        animation-delay: -1.0s;
    }
    .rect4 {
        -webkit-animation-delay: -0.9s;
        animation-delay: -0.9s;
    }
    .rect5 {
        -webkit-animation-delay: -0.8s;
        animation-delay: -0.8s;
    }
}

/* --- FILE UPLOADER --- */
.file-upload {
    position: absolute;
    bottom: 0;
    left: 0;
    input[type=file] {
        display: none;
    }
    label {
        @extend %transition;
        padding: 2px 4px;
        background: $secondary-color;
        color: $main-color;
        cursor: pointer;
        &:hover {
            color: #FFF;
        }
        &:active {
            color: $main-color;
        }
        svg {
            display: inline-block;
            vertical-align: middle;
        }
    }
    .img-dims {
        padding: 2px 4px;
        color: #FFF;
        background: $secondary-color;
        font-size: .8em;
        strong {
            font-weight: 700;
        }
    }
}

/* --- ANIMATIONS --- */
@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes slam
{
    0% {
        transform: scale(10, 10);
        opacity: 0;
    }

    40% {
        opacity: 0;
    }

    100% {
        transform: scale(1, 1);
        opacity: 1;
    }
}
@-webkit-keyframes sk-stretchdelay {
    0%, 40%, 100% {
        -webkit-transform: scaleY(0.4);
    }
    20% {
        -webkit-transform: scaleY(1.0);
    }
}
@keyframes sk-stretchdelay {
    0%, 40%, 100% {
        transform: scaleY(0.4);
        -webkit-transform: scaleY(0.4);
    }
    20% {
        transform: scaleY(1.0);
        -webkit-transform: scaleY(1.0);
    }
}
@keyframes onAutoFillStart {  from {/**/}  to {/**/}}
@keyframes onAutoFillCancel {  from {/**/}  to {/**/}}
input:-webkit-autofill {
    // Expose a hook for JavaScript when autofill is shown
    // JavaScript can capture 'animationstart' events
    animation-name: onAutoFillStart;

    // Make the background color become yellow really slowly
    transition: background-color 50000s ease-in-out 0s, color 50000s ease-in-out 0s;
}
input:not(:-webkit-autofill) {
    // Expose a hook for JS onAutoFillCancel
    // JavaScript can capture 'animationstart' events
    animation-name: onAutoFillCancel;
}

/* --- TYPOGRAPHY --- */
@media (min-width: 30em) {
	body {
    	font-size: calc(.75rem + ((1vw - 0.3em) * 0.2778));
	}
}
@media (min-width: 120em) {
	body {
		font-size: 1em;
	}
}

@media (min-width: 1661px) {
    .courses .course {
        &:nth-child(2n) .about {
            left: 5%;
        }
        &:nth-child(2n-1) .about {
            right: 5%;
        }
        > a {
            flex: auto;
            max-width: 800px;
            img {
                position: relative;
                display: block;
                width: 100%;
                max-width: none;
            }
        }
    }
}

/* --- IPAD LAYOUT ---- */
@media (max-width: 1200px) {
    .footer-copy span {
        display: block;
        &.hide-mobile {
            display: none;
        }
    }
    .footer .fb-like {
        display: none;
    }
    .gallery {
        grid-template-columns: repeat(2, 1fr);
    }
	.main-nav a {
		padding-left: $pad/6;
        padding-right: $pad/6;
        font-size: 1.1em;
    }
    .courses .swf > .about {
        margin-right: 40%;
        p {
            margin-right: 0;
        }
    }
}
@media (max-width: 1200px) and (min-width: 961px) {
    .about.two-col h2 {
        font-size: 3em;
    }
}
@media (max-width: 960px) {
    .swf {
        padding-top: 60px;
        padding-bottom: 60px;
    }
	.two-col {
		flex-direction: column;
		&.about {
			max-height: none;
		}
		&.map {
			flex-direction: column-reverse;
		}
		> * {
			width: 100%;
		}
		.info {
			padding: $pad/2 5%;
		}
		.inner {
			padding: 0!important;
		}
		.img-wrap img {
			margin: 0 auto;
		}
	}
    .cta .swf {
        flex-direction: column;
        align-items: flex-start;
    }
}
@media (min-width: 901px) {
    .courses .course {
        &:nth-child(2n) {
            flex-direction: row-reverse;
            .about {
                padding-left: 0;
                text-align: right;
                h3::before {
                    left: auto;
                    right: 0;
                }
            }
            > a img {
                left: 10%;
            }
            .url {
                flex-direction: row-reverse;
                svg {
                    margin-right: 0;
                    margin-left: 4px;
                }
            }
        }
        &:nth-child(2n-1) {
            .about {
                padding-right: 0;
            }
            > a img {
                right: 10%;
            }
            .file-upload {
                left: auto;
                right: 0;
                text-align: right;
            }
        }
        .about {
            position: relative;
        }
        > a {
            max-width: 800px;
            width: 720px;
            img {
                display: block;
                width: 100%;
                max-width: 800px;
            }
        }
    }
}
@media (max-width: 900px) {
    .hero .info {
        width: 90%;
    }
    .input-group.inline .form-control {
        min-width: 100%;
        margin-top: 5px;
    }
    .courses {
        .swf {
            padding-left: 0;
            padding-right: 0;
            > .about {
                margin-left: 5%;
            }
        }
        .course {
            margin-bottom: 60px;
            .about {
                padding: 0 5%;
            }
            h2 {
                font-size: 2.2em;
            }
        }
    }
    .testimonials .testimonial {
        flex-grow: 9999;
    }
}

/* --- MOBILE LAYOUT ---- */
@media (max-width: 720px) {
    .footer-copy .swf {
        flex-direction: column;
        align-items: center;
        text-align: center;
        p:not(:last-child) {
            margin-bottom: 1em;
        }
    }
}
@media (max-width: 680px) {
    .main-nav {
        margin-left: 0;
    }
	.footer-nav {
        justify-content: center;
    }
    .courses .course {
        flex-direction: column;
        margin-bottom: 80px;
        .about {
            padding-top: 20px;
        }
    }
}
@media (max-width: 600px) {
	.swf {
		padding-top: 10%;
		padding-bottom: 10%;
	}
	.courses .swf > .about {
        margin-right: 5%;
        margin-bottom: 40px;
    }
	.hero {
		.swf {
			padding-bottom: 5%;
		}
		.info, .video, .img-wrap {
			width: 100%;
			margin: 0;
		}
		.info p {
            margin-right: 0;
			margin-bottom: 3.5%;
		}
	}
	.cta .swf h2 {
        margin-top: 0;
    }
    .footer .swf {
        flex-direction: column;
        .social {
            margin-top: 0;
        }
    }
    .footer-nav {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin-bottom: 20px;
        a {
            margin-left: 0;
        }
        .img-wrapper {
            width: 100%;
        }
    }
}
@media (max-width: 425px) {
    .main-nav a {
        padding-left: 8px;
        padding-right: 8px;
    }
    .footer .swf .other-links img {
        width: 100%;
    }
    h1, h2, h3, h4, h5, h6 {
        margin-bottom: 0.4em;
    }
	h1 {
		font-size: 2.8em;
	}
	h2, h3 {
		font-size: 2.3em;
    }
    .gallery {
        grid-template-columns: 1fr;
    }
}
@media (max-width: 400px) {
    .main-nav {
        flex-direction: column;
        align-items: flex-end;
        a {
            margin-left: 0;
        }
    }
}
